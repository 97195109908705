import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';

// import Frame1 from '../_images/profiles/tassel-2.jpg';
// import Frame2 from '../_images/profiles/tassel-3.jpg';
import socialBanner from '../_images/profiles/cosmos-social.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var pageTitle = 'Titans of Waves';
    var pageDesc = 'Cal State Fullerton scientists and their students are on the forefront of discovery, conducting cutting-edge research in a hot, emergent field of astronomy — gravitational waves.';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap cosmos" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              
              <div className="row">
                <h1>{pageTitle}</h1>
                <div className="col-12">

                <p>Cal State Fullerton scientists and their students are on the forefront of discovery, conducting cutting-edge research in a hot, emergent field of astronomy — gravitational waves.</p>
                <p>As part of the international collaboration of researchers that discovered gravitational waves — ripples in the fabric of space-time — Titan scientists played a significant role in proving Einstein’s theory of general relativity.</p>
                <p>Their groundbreaking discovery opened a new window on how the universe works.</p>
                <p>At CSUF's Nicholas and Lee Begovich Center for Gravitational-Wave Physics and Astronomy, student researchers and their mentors study neutron stars, black holes and the cosmic collisions that cause gravitational waves — finding new clues to understanding the universe and unravelling its many mysteries.</p>

                  <h2>Be the difference.</h2>
                  <p><strong>Help further gravitational wave research at Cal State Fullerton.</strong></p>
                  <ul className="actions">
                    <li><a href="http://give.fullerton.edu/70066" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD layout="2" college="nsm" />

                </div>
              </div>
              
            </div>

            

          </div>
          {/* <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Female student in cap and gown holding diploma up" /> */}
          {/* <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="Hispanic students in cap and gown during commencement" /> */}
         
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="waves" college="the College of Natural Sciences & Mathematics" />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
